"use strict";

window.addEventListener(
	"load",
	async (): Promise<void> => {
		const passwordToggleList = Array.prototype.slice.call(
			document.querySelectorAll(".owPasswordToggle")
		) as HTMLElement[];
		passwordToggleList.forEach(
			(toggle: HTMLElement): void => {
				toggle.onclick = onTogglePasswordField;
			}
		);
	}
);

function onTogglePasswordField(event: Event) {
	const toggleElem = event.currentTarget as HTMLElement;
	if (toggleElem) {
		const field = document.querySelector(`#${toggleElem.getAttribute("for")}`) as HTMLInputElement;
		if (field) {
			if (field.type === "password") {
				toggleElem.innerHTML = "Hide";
				field.type = "text";
			} else {
				toggleElem.innerHTML = "Show";
				field.type = "password";
			}
		}

		const linked = toggleElem.getAttribute("data-linked") as string;
		if (linked) {
			const passwordToggleList = Array.prototype.slice.call(
				document.querySelectorAll(".owPasswordToggle")
			) as HTMLElement[];
			passwordToggleList.forEach(
				(toggle: HTMLElement): void => {
					const linkedField = document.querySelector(`#${toggle.getAttribute("for")}`) as HTMLInputElement;
					if (toggle.getAttribute("data-linked") === linked) {
						toggle.innerHTML = toggleElem.innerHTML;
						linkedField.type = field.type;
					}
				}
			);
		}
	}
}
